import React from "react"
import Header from "../components/header"

export default function errorPage() {
  return (
    <div>
      <Header />
      <div className="content">
        <h2>Page not found</h2>
      </div>
    </div>
  )
}
